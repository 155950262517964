import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import TournamentTable, {
  TournamentTableData,
} from './components/TournamentTable';
import MatchTable, { MatchTableData } from './components/MatchTable';
import { apiGet, apiPut } from '../../Services/Api/api';
import {
  TournamentInterface,
  CompetitionInterface,
  TournamentEnhancedInterface,
  MatchInterface,
  AthleteInterface,
} from '../../schemas';
import Swal from 'sweetalert2';
import OrangeButton from '../../Components/Buttons/OrangeButton';
import { Modal } from '../../Components/Modal/Modal';
import TournamentReserveTable from './components/TournamentReserveTable';
import {
  deleteTatami,
  getTatami,
  storeTatami,
} from '../../Services/TournamentManagement/tatami-service';
import { FaArrowLeft, FaPencilAlt } from 'react-icons/fa';
import { AuthContext } from '../../Services/Auth/AuthContext';
import { SecondWindowContext } from '../../Services/SecondWindow/SecondWindowContext';

export default function Tournament() {
  /** for redirect */
  const navigate = useNavigate();
  /** get params from url */
  const [search_params] = useSearchParams();
  const { setSecondWindow } = useContext(SecondWindowContext);
  /**
   * if there is a field 'from_tournament' in the query parameters,
   * it automatically assigns it the active tournament.
   * The usage is that when we redirect from this page to another one,
   * when the page redirect back to this page, the user doesn't need
   * to select again the tournament
   */
  const getTournaments = () => {
    const from_tournament = search_params.get('from_tournament');
    if (!from_tournament) return '';
    return from_tournament;
  };

  const [tournaments, setTournaments] = useState<TournamentInterface[]>([]);
  const [matches, setMatches] = useState<MatchInterface[]>([]);
  const [active_tournament, setActiveTournament] = useState(getTournaments());
  const [active_match, setActiveMatch] = useState<string>('');
  const [n_tatami, setNTatami] = useState(() => getTatami());
  const [is_reserve_open, setIsReserveOpen] = useState(false);
  const { user } = useContext(AuthContext);

  /**
   * get data of tournaments when opening the page
   */
  useEffect(() => {
    if (!user?.competition) {
      return navigate('/');
    }
    const competition: CompetitionInterface =
      user.competition as CompetitionInterface;
    apiGet(`${competition.slug}`).then(
      (tournamentData: TournamentInterface[]) => {
        setTournaments(tournamentData);
      }
    );
  }, []);

  /**
   * update tournaments when reserving new ones
   */
  useEffect(() => {
    if (!is_reserve_open) return;
    if (!user?.competition) {
      return navigate('/');
    }
    const competition: CompetitionInterface =
      user.competition as CompetitionInterface;
    apiGet(`${competition.slug}`).then(
      (tournamentData: TournamentInterface[]) => {
        setTournaments(tournamentData);
      }
    );
  }, [is_reserve_open]);

  /**
   * allows the user to set the tatami number
   */
  useEffect(() => {
    if (n_tatami === null) {
      setMatches([]);
      Swal.fire({
        title: 'Inserire numero Tatami',
        input: 'number',
        preConfirm: (value) => {
          const num = Number(value);
          if (isNaN(num) || !num || num < 1) {
            Swal.showValidationMessage('Inserire numero Tatami valido');
          } else {
            storeTatami(num);
            setNTatami(num);
          }
        },
        allowOutsideClick: false,
      });
    }
  }, [n_tatami]);

  /** when selecting a tournament, load its matches */
  useEffect(() => {
    if (!active_tournament) return;
    if (!user?.competition) {
      return navigate('/');
    }
    const competition: CompetitionInterface =
      user.competition as CompetitionInterface;
    apiGet(`${competition.slug}/${active_tournament}`).then(
      (tournamentData: TournamentEnhancedInterface) => {
        setMatches(tournamentData.matches);
      }
    );
  }, [active_tournament]);

  // TODO do better
  function getTournamentType(n_athletes: number) {
    // best of three
    if (n_athletes < 3) return 'Al Meglio di Tre';
    // round robin
    if (n_athletes < 6) return "Girone all'Italiana";
    // double elimination
    return 'Doppia Eliminazione';
  }

  // TODO using this function does not allow for tournaments to show when opening the page.
  // either refactor or find a way to update the list when new tournaments appear
  function getTournamentsDataForTable(): TournamentTableData[] {
    return tournaments.map((tour) => {
      const n_athletes = tour.athletes.length;
      return {
        _id: tour._id || '',
        name: tour.tournament_name,
        finished: tour.finished,
        tatami_number: tour.tatami_number,
        n_athletes,
        tournament_type: getTournamentType(n_athletes),
      };
    });
  }

  /** returns if one of the athletes of a match is undefined */
  function isMatchWithNullAthlete(match: MatchInterface) {
    if (!match.is_over) return false;
    if (!match?.white_athlete) return true;
    if (!match?.red_athlete) return true;
    return false;
  }

  function getWinnerSide(match: MatchInterface): string {
    if (!match.is_over || !match.winner_athlete) return '';

    const white_athlete = match.white_athlete as AthleteInterface;
    const red_athlete = match.red_athlete as AthleteInterface;
    if (match.winner_athlete === white_athlete._id) return 'Bianco';
    if (match.winner_athlete === red_athlete._id) return 'Rosso';

    throw new Error(
      `How is it possible that winner_athlete is ${match.winner_athlete} and not ${match.white_athlete} or ${match.red_athlete}`
    );
  }

  // TODO cambiare con .map()
  function getMatchesDataForTable() {
    const match_table_data: MatchTableData[] = [];
    for (const match of matches) {
      const white_athlete = match.white_athlete as AthleteInterface;
      const red_athlete = match.red_athlete as AthleteInterface;
      if (isMatchWithNullAthlete(match)) continue;

      match_table_data.push({
        _id: match._id || '',
        whiteAthlete: match.white_athlete
          ? `${white_athlete.surname} ${white_athlete.name}`
          : '',
        redAthlete: match.red_athlete
          ? `${red_athlete.surname} ${red_athlete.name}`
          : '',
        winnerAthlete: getWinnerSide(match),
        isStarted: match.is_started,
        isOver: match.is_over,
      });
    }
    return match_table_data;
  }

  function confirmGoFinishedMatch(title: string, info: string) {
    Swal.fire({
      title,
      html: info,
      showCancelButton: true,
      confirmButtonText: "Continua con l'incontro",
      cancelButtonText: 'Torna Indietro',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(
          `/match-timer/${active_match}?from_tournament=${active_tournament}`
        );
      }
    });
  }

  function startNextMatch() {
    const full_active_match = matches.find((m) => m._id === active_match);
    if (!full_active_match) {
      return Swal.fire('Nessun incontro selezionato', '', 'error');
    }
    if (full_active_match.is_over) {
      return Swal.fire({
        title: "Incontro gia' concluso",
        text: "Non è possibile rigiocare l'incontro perché è già concluso",
        icon: 'error',
      });
    }
    if (full_active_match.is_started) {
      return confirmGoFinishedMatch(
        "Incontro gia' iniziato",
        "Attenzione, l'incontro e' gia' stato iniziato da qualche altro tavolo. Iniziarlo e finirlo qui sovrascriverebbe i dati dell'altro tavolo. Continuare?"
      );
    }

    // TODO ELIMINARE
    /* if (!full_active_match.red_athlete) {
      return Swal.fire({
        title: "Manca l'atleta rosso",
        text: 'Vuoi assegnare la vittoria al bianco?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Si', dai al bianco la vittoria",
        cancelButtonText: 'No, torna indietro ',
      }).then((result) => {
        if (result.isConfirmed) {
          apiPost(`matches/${full_active_match._id}`, {
            winner_athlete: full_active_match.white_athlete._id,
            is_over: true,
            is_started: true,
            scores: {
              final_time: 0,
              white_ippon: 0,
              white_wazaari: 0,
              white_penalties: 0,
              red_ippon: 0,
              red_wazaari: 0,
              red_penalties: 0,
            },
          }).then(() => window.location.reload());
        }
      });
    } */
    // TODO ELIMINARE
    /* if (!full_active_match.white_athlete) {
      return Swal.fire({
        title: "Manca l'atleta bianco",
        text: 'Vuoi assegnare la vittoria al rosso?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Si', dai al rosso la vittoria",
        cancelButtonText: 'No, torna indietro ',
      })
        .then((result) => {
          if (result.isConfirmed) {
            apiPost(`matches/${full_active_match._id}`, {
              winner_athlete: full_active_match.red_athlete._id,
              is_over: true,
              is_started: true,
              scores: {
                final_time: 0,
                white_ippon: 0,
                white_wazaari: 0,
                white_penalties: 0,
                red_ippon: 0,
                red_wazaari: 0,
                red_penalties: 0,
              },
            });
          }
        })
        .then(() => window.location.reload());
    } */

    navigate(
      `/match-timer/${active_match}?from_tournament=${active_tournament}`
    );
  }

  async function reserveTournament(tournamentId: string) {
    const tour_index = tournaments.findIndex(
      (tour) => tour._id === tournamentId
    );
    if (tour_index < 0) return;
    if (tournaments[tour_index].tatami_number.some((n) => n === n_tatami)) {
      return;
    }

    if (tournaments[tour_index].tatami_number.some((n) => n > 0)) {
      const result = await Swal.fire({
        title: 'Torneo già prenotato',
        text: 'Questo torneo è già stato prenotato da un altro tavolo. Prenotandolo esso non sarà più in grado di iniziare altri incontri finché non lo riprenoterà',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sì, lo voglio io',
        cancelButtonText: "No, lascialo com'è",
      });
      if (!result.isConfirmed) return;
    }

    apiPut(`tournaments/reserve/${tournamentId}`, {
      tatami_number: n_tatami,
    });

    setTournaments((prevTournaments) =>
      prevTournaments.map((tour) => {
        if (n_tatami === null || tour._id !== tournamentId) {
          return tour;
        }
        return { ...tour, tatami_number: [n_tatami] };
      })
    );
  }

  function clearTatami() {
    setNTatami(null);
    deleteTatami();
  }

  function getHeader() {
    if (n_tatami === null) {
      return <></>;
    }
    return (
      <>
        <span className='flex items-center text-5xl gap-3'>
          <NavLink to='/manage'>
            <FaArrowLeft className='mr-2' />
          </NavLink>
          Tatami numero {n_tatami}
        </span>
        <button className='text-2xl' onClick={clearTatami}>
          <FaPencilAlt></FaPencilAlt>
        </button>
      </>
    );
  }

  function openBrackets() {
    if (!active_tournament) {
      Swal.fire({
        title: 'Nessun torneo selezionato',
        text: 'Seleziona un torneo prima di aprire il tabellone',
        icon: 'error',
      });
      return;
    }
    navigate(`/tournament/${active_tournament}`);
  }

  return (
    <div className='tournament-container'>
      <div className='n-tatami-container'>{getHeader()}</div>
      <div className='multi-table-container'>
        <div className='table-container'>
          <div className='table-text'>Categorie Prenotate</div>
          <TournamentTable
            tournament_table_data={getTournamentsDataForTable().filter((tour) =>
              tour.tatami_number.some((n) => n === n_tatami)
            )}
            active_tournament={active_tournament}
            setActiveTournament={setActiveTournament}
            no_results_message={
              <>
                Nessun Torneo Disponibile
                <button
                  className='text-sky-500 ml-2 dark:text-sky-400'
                  onClick={() => setIsReserveOpen(true)}
                >
                  Prenota Categorie
                </button>
              </>
            }
          />
        </div>
        <div className='table-container'>
          <div className='table-text'>Lista Incontri</div>
          <MatchTable
            match_table_data={getMatchesDataForTable()}
            active_match={active_match}
            setActiveMatch={setActiveMatch}
          />
        </div>
      </div>
      <div className='button-row'>
        <OrangeButton
          onClickFunction={() => {
            const second_window = window.open(
              '/match-timer/second-monitor',
              'Second Monitor',
              'popup'
            );

            if (second_window) {
              setSecondWindow(second_window);
            }
          }}
        >
          Apri secondo monitor
        </OrangeButton>
        <OrangeButton onClickFunction={() => setIsReserveOpen(true)}>
          Prenota Categorie
        </OrangeButton>
        <OrangeButton onClickFunction={openBrackets}>
          Apri Tabellone
        </OrangeButton>
        <OrangeButton
          onClickFunction={() =>
            navigate(`/match-timer?from_tournament=${active_tournament}`)
          }
        >
          Incontro Amichevole
        </OrangeButton>
        <OrangeButton onClickFunction={() => startNextMatch()}>
          Inizia Incontro Selezionato
        </OrangeButton>
      </div>
      {is_reserve_open && (
        <Modal handleClose={() => setIsReserveOpen(false)}>
          <div className='table-container'>
            <div className='table-text'>Prenota una Categoria</div>
            <TournamentReserveTable
              tournament_table_data={getTournamentsDataForTable()}
              active_tournament={`${n_tatami}`}
              setActiveTournament={reserveTournament}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
