import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../Css/public.css';
import {
  TournamentInterface,
  AthleteEnhancedInterface,
  CompetitionInterface,
} from '../../schemas';
import { apiGet } from '../../Services/Api/api';
import OrangeButton from '../../Components/Buttons/OrangeButton';
import ClubAthleteTable, { ClubAthlete } from './components/ClubAthleteTable';
import DropDown from '../../Components/Inputs/DropDown';

export default function PublicTournaments() {
  // for redirect
  const navigate = useNavigate();

  const [tournaments, setTournaments] = useState<TournamentInterface[]>([]);
  const [selected_tournament, setSelectedTournament] = useState('');
  const [clubs, setClubs] = useState<string[]>([]);
  const [selected_club, setSelectedClub] = useState('');
  const [club_athletes, setClubAthletes] = useState<ClubAthlete[]>([]);
  const [active_athlete, setActiveAthlete] = useState('');
  const current_url = useLocation().pathname.replace('/', '');

  /** get data of tournaments when opening the page */
  useEffect(() => {
    apiGet(`${current_url}`).then((tournamentData: TournamentInterface[]) =>
      setTournaments(tournamentData)
    );
    apiGet('competitions').then((competitions: CompetitionInterface[]) => {
      const competition = competitions.find(
        (comp) => comp.slug === current_url
      );
      const clubs = competition?.clubs.map((club) => club.name) || [];
      setClubs(clubs);
    });
  }, []);

  function getClubAthletes(
    athletesData: AthleteEnhancedInterface[]
  ): ClubAthlete[] {
    return athletesData.map((athlete) => {
      const tour = tournaments.find((tournament) =>
        tournament.athletes.includes(athlete._id!)
      );
      return {
        _id: athlete._id || '',
        name: athlete.name || '',
        surname: athlete.surname || '',
        tournament_name: tour?.tournament_name || '',
        tournament_id: tour?._id,
        tournament_tatami: tour?.tatami_number.filter((n) => !!n).join('/'),
      };
    });
  }

  useEffect(() => {
    if (!selected_club) return;
    apiGet(`${current_url}/club/${selected_club}`).then(
      (athletesData: AthleteEnhancedInterface[]) =>
        setClubAthletes(getClubAthletes(athletesData))
    );
  }, [selected_club]);

  useEffect(() => {
    if (!active_athlete) return;
    const athlete = club_athletes.find((ath) => ath._id === active_athlete);
    if (!athlete) return;
    if (!athlete?.tournament_id) return;
    setSelectedTournament(athlete.tournament_id);
  }, [active_athlete]);

  return (
    <div className='public-container'>
      {/* TODO ottenere il vero competition URL */}
      <div className='competition-name'>{current_url}</div>
      <DropDown
        options={tournaments.map((tour) => {
          return {
            value: tour._id || '',
            name: tour.tournament_name,
          };
        })}
        selected_option={selected_tournament}
        chooseOption={(optionValue: string) =>
          setSelectedTournament(optionValue)
        }
      >
        Scegli Categoria
      </DropDown>
      {selected_tournament && (
        <OrangeButton
          onClickFunction={() => {
            navigate(`${selected_tournament}`);
          }}
        >
          Apri Tabellone
        </OrangeButton>
      )}
      <div className='competition-name'>Cerca i tuoi Atleti</div>
      <DropDown
        options={clubs.map((club) => {
          return { value: club, name: club };
        })}
        selected_option={selected_club}
        chooseOption={(optionValue: string) => setSelectedClub(optionValue)}
      >
        Scegli Club
      </DropDown>
      {selected_club && (
        <ClubAthleteTable
          club_athletes={club_athletes}
          tournaments={tournaments}
          active_athlete={active_athlete}
          setActiveAthlete={setActiveAthlete}
        />
      )}
    </div>
  );
}
