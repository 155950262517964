import React from 'react';

export type MatchTableData = {
  _id: string;
  whiteAthlete: string;
  redAthlete: string;
  winnerAthlete: string;
  isStarted: boolean;
  isOver: boolean;
};

export default function MatchTable(props: {
  match_table_data: MatchTableData[];
  active_match: string;
  setActiveMatch: (matchId: string) => void;
}) {
  const { match_table_data, active_match, setActiveMatch } = props;

  function getRowClass(match: MatchTableData) {
    if (active_match === match._id) return 'active-row cursor-pointer';
    if (match.isOver) return 'finished-row';
    if (match.isStarted) return 'warning-row cursor-pointer';
    return 'cursor-pointer';
  }

  function getTableElements() {
    if (match_table_data.length === 0) {
      return (
        <tr className='table-empty'>
          <td colSpan={3}>Nessun Incontro Disponibile</td>
        </tr>
      );
    }

    return match_table_data
      .filter((m) => !!m.whiteAthlete && !!m.redAthlete)
      .map((match) => (
        <tr
          key={match._id}
          className={getRowClass(match)}
          onClick={() => setActiveMatch(match._id)}
        >
          <td className='table-column-40'>{match.whiteAthlete}</td>
          <td className='table-column-40'>{match.redAthlete}</td>
          <td className='table-column-20'>
            {(() => {
              if (!match.winnerAthlete) return '';
              if (match.winnerAthlete === match.whiteAthlete) return 'Bianco';
              if (match.winnerAthlete === match.redAthlete) return 'Rosso';
              return '';
            })()}
          </td>
        </tr>
      ));
  }

  return (
    <table className='table'>
      <thead>
        <tr>
          <td className='table-column-40'>Atleta Bianco</td>
          <td className='table-column-40'>Atleta Rosso</td>
          <td className='table-column-20'>Vince</td>
        </tr>
      </thead>
      <tbody>{getTableElements()}</tbody>
    </table>
  );
}
